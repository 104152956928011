<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard>
          <CCardHeader>
            الفئةالفرعية:  {{ $route.params.id }}
          </CCardHeader>
          <CCardBody>
            <CDataTable
              striped
              small
              fixed
              :items="visibleData"
              :fields="fields"
            />
          </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="goBack">رجوع</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'SubCategory',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.categoriesOpened = from.fullPath.includes('SubCategories')
    })
  },
  data () {
    return {
      categoriesOpened: null
    }
  },
  created() {
        this.$http
            .get('${process.env.VUE_APP_URL}sub-categories')
            .then(response => {
                this.SubCategories = response.data;
            });
    },
  computed: {
    fields () {
      return [
        { key: 'key', label: this.name, _style: 'width:150px'},
        { key: 'value', label: '', _style: 'width:150px;' }
      ]
    },
    categoryData () {
      const id = this.$route.params.id
      const SubCategory = categoriesData.find((SubCategory, index) => index + 1 == id)
      const categoryDetails = SubCategory ? Object.entries(SubCategory) : [['id', 'Not found']]
      return categoryDetails.map(([key, value]) => { return { key, value } })
    },
    visibleData () {
      return this.categoryData.filter(param => param.key !== 'name')
    },
    categoryname () {
      return this.categoryData.filter(param => param.key === 'name')[0].value
    }
  },
  methods: {
    goBack() {
      this.categoriesOpened ? this.$router.go(-1) : this.$router.push({path: '/SubCategories'})
    },
    updateCategory() {
                this.axios
                    .post(`http://localhost:8000/api/book/update/${this.$route.params.id}`, this.book)
                    .then((response) => {
                        this.$router.push({name: 'home'});
                    });
            }
  }
}
</script>
